
    import { createApp } from 'vue';
    import App from './assets/javascript/LoginApp.vue';
    import { createRouter, createWebHashHistory } from "vue-router";

    const routes = []
    const router = createRouter({
      history: createWebHashHistory(),
      routes
    })

    const app = createApp(App);
    app.use(router);
    app.provide('router',router);
    app.mount('#app');

  