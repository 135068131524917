<script setup>
import {provide, ref, onMounted, inject} from 'vue';
import Auth from "./components/Auth.vue";
import MessageBox from "./components/MessageBox.vue";

const messageBox = ref();
const authApi = ref();
const router = inject('router');

const showMessage = function (header, message, type = 'success', icon) {
  messageBox.value.addMessage(header,message, type, icon);
};

provide('showMessage',showMessage);

onMounted(()=>{
  let callback = LOGIN_CALLBACK !== 'None' ? window.location.origin+LOGIN_CALLBACK : false
  authApi.value.openLoginModal(callback);
})

</script>

<template>
  <Auth ref="authApi"></Auth>
  <message-box ref="messageBox"></message-box>
</template>